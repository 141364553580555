import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './styles';

const SearchInput = ({ onInputChange, delay }) => {
    const [inputValue, setInputValue] = useState('');

    const classes = useStyles();

    const handleOnDelayedChange = useMemo(() => debounce(onInputChange, delay), [delay, onInputChange]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
        handleOnDelayedChange(e.target.value);
    };

    useEffect(() => {
        return () => {
            handleOnDelayedChange.cancel();
        };
    }, [handleOnDelayedChange]);

    return (
        <div className={classes.root}>
            <input className={classes.input} placeholder="Search..." value={inputValue} onChange={handleChange} />
            <FontAwesomeIcon icon={['fal', 'search']} className={classes.icon} />
        </div>
    );
};

SearchInput.defaultProps = {
    delay: 300,
};

SearchInput.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    delay: PropTypes.number,
};

export default SearchInput;
