import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: theme.spacing(3),
    },
    contentWrapper: {
        textAlign: 'center',
        maxHeight: 230,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'rgba(0, 0, 0, 0.06)',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#D0D0D0',
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#B0B0B0',
        },
    },
    buttonWrapper: {
        position: 'absolute',
        bottom: -55,
        width: '100%',
    },
    button: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        display: 'flex',
        margin: '16px auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
    },
    buttonIconWrapper: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
    buttonIcon: {
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(16),
        transform: 'rotate(90deg)',
    },
    rotatedIcon: {
        transform: 'rotate(270deg)',
    },
}));
