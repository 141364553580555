import React, { useCallback, useEffect, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import client from '$landing-page/client';
import PublicationsAccordionList from '$landing-page/components/publications-accordion-list';
import { PUBLICATION_STATUS } from '$landing-page/constants';
import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import SearchInput from './search-input';
import useStyles from './styles';
import TagsAccordion from './tags-accordion';

// TODO: This whole page should be revamped. We are planning to add React-query, pagination for publications, autoComplete input for selecting tags, etc.
const PublicationsIndexPage = (props) => {
    const { location } = props;
    const [publications, setPublications] = useState({ loading: true, items: [], isError: false });
    const [allTags, setAllTags] = useState({ loading: true, items: [], isError: false });
    const [visibleTags, setVisibleTags] = useState([]);
    const [publicationFilters, setPublicationFilters] = useState({ title: '', tags: [] });

    const classes = useStyles();

    const onUpdateSearchInput = useCallback(
        (value) => {
            if (value === '') {
                setVisibleTags(allTags.items);
            } else {
                const newFilteredTags = allTags.items.filter((tag) => tag.tagName.toLowerCase().includes(value.toLowerCase()));
                setVisibleTags(newFilteredTags);
            }
        },
        [allTags.items]
    );

    const onToggleTag = useCallback(
        (tagItem) => {
            let newSelectedTags = [];
            const isTagSelected = !!publicationFilters.tags.find((tag) => tag.id === tagItem.id);
            if (isTagSelected) {
                newSelectedTags = publicationFilters.tags.filter((tag) => tag.id !== tagItem.id);
            } else {
                newSelectedTags = [...publicationFilters.tags, tagItem];
            }
            setPublicationFilters((previousState) => ({ ...previousState, tags: newSelectedTags }));
        },
        [publicationFilters.tags]
    );

    useEffect(() => {
        let isUnmounted = false;
        client
            .api('/sales/publication/tags/')
            .then((response) => {
                if (!isUnmounted) {
                    const tagsData = camelcaseKeys(response.data.tags, { deep: true });
                    setAllTags({ loading: false, items: tagsData });
                    setVisibleTags(tagsData);
                }
            })
            .catch(() => {
                setAllTags({ loading: false, items: [], isError: true });
            });

        return () => {
            isUnmounted = true;
        };
    }, []);

    useEffect(() => {
        let isUnmounted = false;
        setPublications((previousState) => ({ ...previousState, loading: true }));
        const tagIdsString = publicationFilters.tags.map((tag) => tag.id).toString();

        // TODO-MAYBE: We can search locally since we get all of the publications from API.
        client
            .api(`/sales/publication/?status_id=${PUBLICATION_STATUS.published}${tagIdsString ? `&tag_ids=${tagIdsString}` : ''}`)
            .then((response) => {
                if (!isUnmounted) {
                    const publicationsData = camelcaseKeys(response.data.publications, { deep: true });
                    setPublications({ loading: false, items: publicationsData });
                }
            })
            .catch(() => {
                setPublications({ loading: false, items: [], isError: true });
            });

        return () => {
            isUnmounted = true;
        };
    }, [publicationFilters]);

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title="Publications"
            description="Ethica offers a flexible platform which can be configured for many different scientific studies. The following publications demonstrate how Ethica can help different study designs."
            classes={{ contentHeaderWrapper: classes.contentHeaderWrapper }}
            content={
                <Grid container direction="column" justify="center" alignItems="center">
                    <div className={classes.headerTitlesWrapper}>
                        <Typography variant="h1" className={classes.headerTitle}>
                            Publications
                        </Typography>
                        <Typography variant="body1" className={classes.headerSubtitle}>
                            Ethica offers a flexible platform which can be configured for many different scientific studies. The
                            following publications demonstrate how Ethica can help different study designs.
                        </Typography>
                    </div>
                    <SearchInput onInputChange={onUpdateSearchInput} />
                    <TagsAccordion
                        tags={visibleTags}
                        selectedTags={publicationFilters.tags}
                        onTagClick={onToggleTag}
                        visibleCount={5}
                        loading={allTags.loading}
                        error={allTags.isError}
                    />
                </Grid>
            }
        >
            <PublicationsAccordionList items={publications.items} loading={publications.loading} error={publications.isError} />
        </BigContentHeaderLayout>
    );
};

PublicationsIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default PublicationsIndexPage;
