import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 800,
        position: 'relative',
    },
    input: {
        width: '100%',
        display: 'block',
        padding: theme.spacing(2, 3),
        margin: '0 auto',
        border: '1px solid #D0D9DE',
        borderRadius: 40,
        outline: 'none',
    },
    icon: {
        position: 'absolute',
        right: 20,
        top: 0,
        bottom: 0,
        margin: 'auto 0',
    },
}));
