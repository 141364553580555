import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    contentHeaderWrapper: {
        paddingBottom: theme.spacing(7),
        boxShadow: 'none',
    },
    headerTitle: {
        fontSize: theme.typography.pxToRem(32),
    },
    headerSubtitle: {
        color: '#606060',
        margin: theme.spacing(2, 0),
    },
    headerTitlesWrapper: {
        maxWidth: 600,
        textAlign: 'center',
    },
}));
