import React, { useState } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';

import Loading from '$landing-page/components/loading';
import Tag from '$landing-page/components/tag';

import useStyles from './styles';

const TagsAccordion = (props) => {
    const { tags, onTagClick, selectedTags, visibleCount, loading, error } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles(props);

    const isTagSelected = (id) => {
        return !!selectedTags.find((tag) => tag.id === id);
    };

    return (
        <div className={classes.root}>
            <Loading loading={loading} error={error} />
            {!loading && !error && tags.length === 0 ? (
                <p>No keyword found!</p>
            ) : (
                <div className={classes.contentWrapper}>
                    {tags.slice(0, visibleCount).map((tag) => (
                        <Tag key={tag.id} name={tag.tagName} selected={isTagSelected(tag.id)} onClick={() => onTagClick(tag)} />
                    ))}
                    {isExpanded && (
                        <div>
                            {tags.slice(visibleCount, tags.length).map((tag) => (
                                <Tag
                                    key={tag.id}
                                    name={tag.tagName}
                                    selected={isTagSelected(tag.id)}
                                    onClick={() => onTagClick(tag)}
                                />
                            ))}
                        </div>
                    )}
                    {tags.length > visibleCount && (
                        <div className={classes.buttonWrapper}>
                            <button
                                className={classes.button}
                                type="button"
                                onClick={() => setIsExpanded((previousState) => !previousState)}
                            >
                                <Typography variant="caption" className={classes.buttonText}>
                                    {isExpanded ? 'Collapse' : 'More Keywords'}
                                </Typography>
                                <div className={classes.buttonIconWrapper}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'angle-right']}
                                        className={clsx(classes.buttonIcon, { [classes.rotatedIcon]: isExpanded })}
                                    />
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

TagsAccordion.defaultProps = {
    loading: false,
    error: false,
};

TagsAccordion.propTypes = {
    tags: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    onTagClick: PropTypes.func.isRequired,
    visibleCount: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool,
};

export default TagsAccordion;
